import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Container,
    Grid,
    Box,
    Typography,
    Button,
    Snackbar,
    TextField,
} from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const UserInvitationConfirmation = datacheck => {
    const location = useLocation();

    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsOpen(false);
    };

    return location.state?.data?.password ? (
        <Container maxWidth="md">
            <br />
            <Typography variant="h5" gutterBottom>
                Invitation info:
            </Typography>
            <Box my={2}>
                <Grid
                    container
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={3}>
                        <Typography variant="h6" component="p">
                            Link:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            defaultValue={location.state?.data?.invitationLink}
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CopyToClipboard
                            text={location.state?.data?.invitationLink}
                            onCopy={handleOpen}
                        >
                            <Button variant="contained" color="secondary">
                                Copy
                            </Button>
                        </CopyToClipboard>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={3}>
                        <Grid item xs={3}>
                            <Typography variant="h6" component="p">
                                Password:
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            defaultValue={location.state?.data?.password}
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CopyToClipboard
                            text={location.state?.data?.password}
                            onCopy={handleOpen}
                        >
                            <Button variant="contained" color="secondary">
                                Copy
                            </Button>
                        </CopyToClipboard>
                    </Grid>
                </Grid>
            </Box>
            <Snackbar
                open={isOpen}
                autoHideDuration={2000}
                onClose={handleClose}
                message="Copied to clipboard"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            />
        </Container>
    ) : (
        <Container maxWidth="md">
            <br />
            <Typography variant="h5" gutterBottom>
                Invitation info:
            </Typography>
            <Box my={2}>
                <Grid
                    container
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={3}>
                        <Typography variant="h6" component="p">
                            Link:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            defaultValue={location.state?.data?.invitationLink}
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CopyToClipboard
                            text={location.state?.data?.invitationLink}
                            onCopy={handleOpen}
                        >
                            <Button variant="contained" color="secondary">
                                Copy
                            </Button>
                        </CopyToClipboard>
                    </Grid>
                </Grid>
            </Box>
            <Snackbar
                open={isOpen}
                autoHideDuration={2000}
                onClose={handleClose}
                message="Copied to clipboard"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            />
        </Container>
    );
};

export default UserInvitationConfirmation;
