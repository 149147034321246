import {
    TextInput,
    SimpleForm,
    required,
    email,
    useRedirect,
    useNotify,
    minLength,
    maxLength,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import baseUrl from '../../../enums/base-url.enum';
import httpHeader, { withAcsrf } from '../../../utils/http-header';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PhoneNumberInput from '../../../custom-pages/phone-number-input';
import CorporateMailValidator from '../../../validators/corporate-mail-validator';
import HumanNameValidator from '../../../validators/human-name-validator';
import ErrorHandler from '../../../utils/error-handler';
import isLowerCaseEmailValidator from '../../../validators/is-lowercase-email-validator';

const RapcInvitationBusiness = () => {
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const redirect = useRedirect();
    const notify = useNotify();
    const handleClick = async data => {
        setLoading(true);
        fetch(baseUrl.baseUrl + `/rapc`, {
            method: 'POST',
            headers: await withAcsrf(httpHeader()),
            body: JSON.stringify({
                firstName: data.firstName,
                lastName: data.lastName,
                phoneNumber: data.phoneNumber || null,
                email: data.email,
                businessId: location.state?.data?.id,
            }),
        })
            .then(data => {
                data.json()
                    .then(function (data) {
                        if (data.status === 'error') {
                            ErrorHandler(data, redirect, notify);
                            redirect(
                                '/rapc-admin/invitation-business',
                                null,
                                null,
                                {},
                                { data: location.state?.data }
                            );
                            notify(`${data.message}`, { type: 'warning' });
                        } else {
                            redirect(
                                '/rapc-admin/invitation/confirmation',
                                null,
                                null,
                                {},
                                { data }
                            );
                            notify('Business successfully created!', {
                                type: 'success',
                            });
                        }
                    })
                    .catch(function (e) {
                        notify(e);
                        redirect(
                            '/rapc-admin/invitation-business',
                            null,
                            null,
                            {},
                            { data: location.state?.data }
                        );
                    });
            })
            .catch(e => {
                setLoading(false);
                notify('Error: user suspension failed', 'warning');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSubmit = event => {
        confirmAlert({
            title: 'Confirm to Invite RAPC Admin',
            message: `Are you sure to invite current admin with mail ${event.email} ?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleClick(event),
                },
                {
                    label: 'No',
                    onClick: () =>
                        redirect(
                            '/rapc-admin/invitation-business',
                            null,
                            null,
                            {},
                            { data: location.state?.data }
                        ),
                },
            ],
        });
    };

    return (
        <Box display="flex" my={2}>
            <Box flex={1}>
                <SimpleForm onSubmit={handleSubmit} sx={{ maxWidth: 500 }}>
                    <Typography variant="h6" gutterBottom>
                        Invite RAPC Admin Business Info:
                    </Typography>
                    <TextInput
                        name="firstName"
                        validate={[
                            required(),
                            minLength(3),
                            maxLength(30),
                            HumanNameValidator,
                        ]}
                        source="firstName"
                        fullWidth
                    />
                    <TextInput
                        name="lastName"
                        validate={[
                            required(),
                            minLength(3),
                            maxLength(30),
                            HumanNameValidator,
                        ]}
                        source="lastName"
                        fullWidth
                    />
                    <PhoneNumberInput fullWidth source="phoneNumber" />
                    <br />
                    <TextInput
                        name="email"
                        validate={[
                            required(),
                            email(),
                            CorporateMailValidator,
                            isLowerCaseEmailValidator,
                        ]}
                        value={email}
                        source="email"
                        fullWidth
                    />
                </SimpleForm>
            </Box>
        </Box>
    );
};

export default RapcInvitationBusiness;
