import {
    TextInput,
    SimpleForm,
    required,
    useRedirect,
    useNotify,
    minLength,
    maxLength,
    email,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import 'react-confirm-alert/src/react-confirm-alert.css';
import baseUrl from '../../../enums/base-url.enum';
import httpHeader, { withAcsrf } from '../../../utils/http-header';
import { useState } from 'react';
import PhoneNumberInput from '../../../custom-pages/phone-number-input';
import UserRoleSelectInput from '../../../custom-pages/user-role-select-input';
import CompanySelectInput from '../../../custom-pages/company-select-input';
import ErrorHandler from '../../../utils/error-handler';
import HumanNameValidator from '../../../validators/human-name-validator';
import CorporateMailValidator from '../../../validators/corporate-mail-validator';
import isLowerCaseEmailValidator from '../../../validators/is-lowercase-email-validator';

const UserInvitation = () => {
    const [loading, setLoading] = useState(false);
    const redirect = useRedirect();
    const notify = useNotify();

    const handleClick = async data => {
        setLoading(true);
        fetch(baseUrl.baseUrl + `/user`, {
            method: 'POST',
            headers: await withAcsrf(httpHeader()),
            body: JSON.stringify({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phoneNumber: data.phoneNumber || null,
                ...(data.role && {
                    role: data.role,
                }),
                ...(data.company && {
                    companyId: data.company,
                }),
            }),
        })
            .then(data => {
                data.json()
                    .then(function (data) {
                        // [TODO] need to be correct in another way like
                        // return error with shortcode on backend side
                        console.log(data);
                        if (data.status === 'error') {
                            ErrorHandler(data, redirect, notify);
                            redirect('/user-custom/invitation-user');
                            notify(`${data.message}`, { type: 'warning' });
                        } else {
                            redirect(
                                '/user-custom/invitation/confirmation',
                                null,
                                null,
                                {},
                                { data: data }
                            );
                            notify('User successfully invited!', {
                                type: 'success',
                            });
                        }
                    })
                    .catch(function (e) {
                        notify(e);
                        redirect('/user-custom/invitation-user');
                    });
            })
            .catch(e => {
                setLoading(false);
                notify('Error: user suspension failed', 'warning');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Box display="flex" my={2}>
            <Box flex={1}>
                <SimpleForm onSubmit={handleClick} sx={{ maxWidth: 500 }}>
                    <Typography variant="h6" gutterBottom>
                        Invite User:
                    </Typography>
                    <TextInput
                        source="firstName"
                        validate={[
                            required(),
                            minLength(3),
                            maxLength(30),
                            HumanNameValidator,
                        ]}
                        fullWidth
                    />
                    <TextInput
                        source="lastName"
                        validate={[
                            required(),
                            minLength(3),
                            maxLength(30),
                            HumanNameValidator,
                        ]}
                        fullWidth
                    />
                    <TextInput
                        source="email"
                        fullWidth
                        validate={[
                            required(),
                            email(),
                            CorporateMailValidator,
                            isLowerCaseEmailValidator,
                        ]}
                    />
                    <PhoneNumberInput fullWidth source="phoneNumber" />
                    <br />
                    <UserRoleSelectInput
                        name="role"
                        source="role"
                        validate={required()}
                        fullWidth
                    />
                    <CompanySelectInput
                        name="company"
                        source="company"
                        validate={required()}
                        fullWidth
                    />
                </SimpleForm>
            </Box>
        </Box>
    );
};

export default UserInvitation;
