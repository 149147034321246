import { acsrf } from './acsrf';

const httpHeader = (headerOptions = {}) => {
    const header = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
    });

    Object.entries(headerOptions).forEach(([key, value]) => {
        header.set(key, value);
    });

    const auth = JSON.parse(localStorage.getItem('auth'));
    if (auth) {
        header.set('Authorization', `Bearer ${auth.token}`);
    }
    return header;
};

export const withAcsrf = async header => {
    const { key: acsrfKey, token: acsrfToken } = await acsrf.generateToken();
    header.set('x-csrf-key', acsrfKey);
    header.set('x-csrf-token', acsrfToken);
    return header;
};

export default httpHeader;
