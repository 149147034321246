import * as React from 'react';
import {
    Admin,
    Resource,
    CustomRoutes,
    Login,
    Authenticated,
} from 'react-admin';
import { SmaList } from './resources/sma/sma-list';
import Dashboard from './Dashboard';
import authProvider from './providers/auth-provider.provider';
import lightTheme from './themes/light-theme';
import dataProvider from './providers/base-data.provider';
import Settings from './custom-pages/setting';
import SMAInvitation from './resources/sma/custom-pages/invitation';
import SMAInvitationConfirmation from './resources/sma/custom-pages/invitation-confirmation';
import { Route } from 'react-router-dom';
import SmaEdit from './resources/sma/sma-edit';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import GroupIcon from '@mui/icons-material/Group';
import { CompanyList } from './resources/company/company-list';
import resourceEnum from './enums/resource.enum';
import Registration from './custom-pages/registration';
import Setup from './custom-pages/setup';
import RAPCInvitationConfirmation from './resources/company/custom-pages/invitation-confirmation';
import RapcInvitationBusiness from './resources/company/custom-pages/invitation-business';
import RAPCInvitationCompany from './resources/company/custom-pages/invitation-company';
import CompanyEdit from './resources/company/company-edit';
import { UserList } from './resources/user/user-list';
import UserEdit from './resources/user/user-edit';
import { RapcList } from './resources/rapc/rapc-list';
import RapcEdit from './resources/rapc/rapc-edit';
import UserInvitation from './resources/user/custom-pages/invitation-user';
import UserInvitationConfirmation from './resources/user/custom-pages/invitation-confirmation';

const MyLoginPage = () => <Login backgroundImage="../../bg.jpg" />;
const App = () => (
    <Admin
        title="My Secro Admin Dashboard"
        theme={lightTheme}
        loginPage={MyLoginPage}
        dashboard={Dashboard}
        authProvider={authProvider}
        dataProvider={dataProvider}
    >
        <Resource
            options={{ label: 'Secro Main Admin (SMA)' }}
            name="sma"
            list={SmaList}
            edit={SmaEdit}
            icon={PersonIcon}
        />

        <Resource
            options={{ label: 'Companies' }}
            name={resourceEnum.company}
            list={CompanyList}
            edit={CompanyEdit}
            icon={BusinessIcon}
        />
        <Resource
            options={{ label: 'Users' }}
            name={resourceEnum.user}
            list={UserList}
            edit={UserEdit}
            icon={GroupIcon}
        />
        <Resource
            options={{ label: 'Rapc' }}
            name={resourceEnum.rapc}
            list={RapcList}
            edit={RapcEdit}
            icon={EmojiPeopleIcon}
        />
        <Resource
            options={{ label: 'Sample page' }}
            name="settings"
            list={Settings}
        />
        <CustomRoutes>
            <Route
                path="/settings"
                element={
                    <Authenticated>
                        <Settings />
                    </Authenticated>
                }
            />
            <Route path="/sma-admin/">
                <Route
                    path="invitation"
                    element={
                        <Authenticated>
                            <SMAInvitation />
                        </Authenticated>
                    }
                />

                <Route
                    path="invitation/confirmation"
                    element={
                        <Authenticated>
                            <SMAInvitationConfirmation />
                        </Authenticated>
                    }
                />
            </Route>
            <Route path="/rapc-admin/">
                <Route
                    path="invitation-company"
                    element={
                        <Authenticated>
                            <RAPCInvitationCompany />
                        </Authenticated>
                    }
                />
                <Route
                    path="invitation-business"
                    element={
                        <Authenticated>
                            <RapcInvitationBusiness />
                        </Authenticated>
                    }
                />
                <Route
                    path="invitation/confirmation"
                    element={
                        <Authenticated>
                            <RAPCInvitationConfirmation />
                        </Authenticated>
                    }
                />
            </Route>
            <Route path="/user-custom/">
                <Route
                    path="invitation-user"
                    element={
                        <Authenticated>
                            <UserInvitation />
                        </Authenticated>
                    }
                />
                <Route
                    path="invitation/confirmation"
                    element={
                        <Authenticated>
                            <UserInvitationConfirmation />
                        </Authenticated>
                    }
                />
            </Route>
        </CustomRoutes>

        <CustomRoutes noLayout>
            <Route path="/registration/:token" element={<Registration />} />
            <Route
                path="/setup"
                element={
                    <Authenticated>
                        <Setup />
                    </Authenticated>
                }
            />
        </CustomRoutes>
    </Admin>
);

export default App;

// NOTE:
// Front-end issues:  more validations + api call drop-down
// Backend-end issues:  phone-number validation + add jurisdiction code to business maybe
