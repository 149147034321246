import * as React from 'react';
import { CardContent, CardHeader, Box } from '@mui/material';
import { Link } from 'react-router-dom';

/**
 * @NOTE
 * List ACtion component give us ability to customize
 * buttons on the toolbar on the top of list of records
 */
const dashboard = () => (
    <Box width="85%" height="85%">
        <CardHeader title="Welcome to Secro dashboard!" />
        <CardContent>Best place for manage your admins on Secro!</CardContent>
        <Link to="/settings">go to Setting page</Link>
    </Box>
);

export default dashboard;
