import baseUrl from '../enums/base-url.enum';

const acsrf = {
    generateToken: async () => {
        try {
            const key = crypto.randomUUID();
            const request = new Request(
                `${baseUrl.apiUrl}/auth/acsrf/pub/${process.env.REACT_APP_ANTI_CSRF_SSR_API_SECRET_KEY}/${key}`,
                {
                    method: 'GET',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                    }),
                }
            );
            const response = await fetch(request);
            const token = await response.text();
            return { key, token };
        } catch {
            console.error(
                'ERR: There was a problem while generating ACSRF token and an empty pair will be attached to the request.'
            );
            return { key: '', token: '' };
        }
    },
};

export { acsrf };
