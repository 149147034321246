import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useRedirect, useNotify } from 'react-admin';
import 'react-confirm-alert/src/react-confirm-alert.css';
import baseUrl from '../enums/base-url.enum';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import httpHeader, { withAcsrf } from '../utils/http-header';

const schema = yup
    .object({
        password: yup.string().required(),
    })
    .required();

const theme = createTheme({
    palette: {
        primary: {
            main: '#026062',
        },
    },
});

const Registration = () => {
    let { token } = useParams();
    const {
        register,
        handleSubmit: handleValidation,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const [loading, setLoading] = useState(false);
    const redirect = useRedirect();
    const notify = useNotify();
    const handleSubmit = async event => {
        const data = new FormData(event.currentTarget);
        setLoading(true);

        fetch(baseUrl.baseUrl + `/sma/auth`, {
            method: 'POST',
            headers: await withAcsrf(httpHeader()),
            body: JSON.stringify({
                invitationToken: token,
                password: event.password,
            }),
        })
            .then(data => {
                data.json()
                    .then(function (data) {
                        if (data.status === 'error') {
                            redirect(`/registration/${token}`);
                            notify(`Invalid password!`, { type: 'warning' });
                        } else {
                            localStorage.setItem(
                                'auth',
                                JSON.stringify({ token: data.token })
                            );
                            redirect('/setup', null, null, {}, { data });
                        }
                    })
                    .catch(function (e) {
                        redirect(`/registration/${token}`);
                        notify(e);
                    });
            })
            .catch(e => {
                setLoading(false);
                notify('Error: user registration failed', 'warning');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img width={56} src="brand-logo.png" alt="Semy Sharp" />
                    <Typography component="h1" variant="h5">
                        Welcome to Secro
                    </Typography>

                    <Typography component="h1" variant="h6">
                        Please insert the your password
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleValidation(handleSubmit)}
                        sx={{ mt: 3 }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={34} sm={26}>
                                <TextField
                                    {...register('password', {
                                        required: true,
                                    })}
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    fullWidth
                                    error={
                                        errors.password?.type === 'required'
                                            ? true
                                            : false
                                    }
                                    helperText={
                                        errors.password?.type === 'required'
                                            ? 'password is required'
                                            : ''
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 4, mb: 6 }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default Registration;
