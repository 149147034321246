import { SelectInput, AutocompleteInput } from 'react-admin';
import { useState, useEffect } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import baseUrl from '../enums/base-url.enum';
import httpHeader from '../utils/http-header';

const CompanySelectInput = props => {
    const [companies, setCompanies] = useState([]);
    useEffect(() => {
        async function fetchData() {
            const neededBaseUrl = baseUrl.baseUrl;
            fetch(neededBaseUrl + `/company/names`, {
                method: 'GET',
                headers: httpHeader(),
            }).then(data => {
                data.json().then(function (data) {
                    setCompanies(data);
                });
            });
        }

        fetchData();
    }, []);
    return (
        <AutocompleteInput
            {...props}
            optionText="name"
            optionValue="id"
            source="company"
            choices={companies}
        />
    );
};

export default CompanySelectInput;
