import * as React from 'react';
import DeleteRowButton from '../../buttons/delete-row-button';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    Filter,
    EmailField,
    ResourceContextProvider,
    useRecordContext,
} from 'react-admin';
import SmaListActions from './components/sma-list.action';
import Typography from '@mui/material/Typography';
import { Fragment } from 'react';
import SuspendSmaButton from './components/suspend-sma-button';
import ReinviteSmaButton from './components/reinvite-sma-button';
import RestoreAccessSmaButton from './components/restore-access-sma-button';
import resourceEnum from '../../enums/resource.enum';

const BulkActionButtons = props => (
    <Fragment>{/* Add buttons here */}</Fragment>
);

// NOTE: fix this filters later
const SmaFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const Aside = () => (
    <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="h6">Secro main admin details</Typography>
        <Typography variant="body2">
            Admins will only be published one an editor approves them
        </Typography>
    </div>
);

const ConditionalButtons = () => {
    const record = useRecordContext();
    const userId = localStorage.getItem('userId');
    if (record.id !== Number(userId)) {
        if (record.status === 'invited') {
            return (
                <>
                    <ReinviteSmaButton disabled={false} name={'reinvite'} />
                    <DeleteRowButton
                        disabled={false}
                        recource={resourceEnum.sma}
                    />
                </>
            );
        }

        if (record.status === 'active')
            return (
                <>
                    {record.status === 'active' ? (
                        <SuspendSmaButton disabled={false} name={'suspend'} />
                    ) : null}
                    <DeleteRowButton
                        disabled={false}
                        recource={resourceEnum.sma}
                    />
                </>
            );

        if (record.status === 'suspended')
            return (
                <>
                    {record.status === 'suspended' && (
                        <RestoreAccessSmaButton
                            disabled={false}
                            name={'restore access'}
                        />
                    )}
                    <DeleteRowButton
                        disabled={false}
                        recource={resourceEnum.sma}
                    />
                </>
            );
    } else {
        return null;
    }

    return null;
};

export const SmaList = props => {
    return (
        <ResourceContextProvider value="sma">
            <List
                empty={false}
                hasCreate={false}
                {...props}
                actions={<SmaListActions />}
                aside={<Aside />}
                filters={<SmaFilter />}
                bulkActionButtons={<BulkActionButtons />}
            >
                <Datagrid rowClick="edit">
                    <TextField source="id" />
                    <TextField source="firstName" />
                    <TextField source="lastName" />
                    <TextField source="role" />
                    <TextField source="status" />
                    <EmailField source="email" />
                    {/* NOTE: these buttons added for POC demo will be changed with real implementation */}
                    {/* <ConditionalRestoreAccessSmaButton />
                    <ConditionalSuspendSmaButton />
                    <ConditionalReinviteSmaButton /> */}
                    <ConditionalButtons />

                    <br />
                </Datagrid>
            </List>
        </ResourceContextProvider>
    );
};
