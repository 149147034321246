import baseUrl from '../enums/base-url.enum';
import httpHeader, { withAcsrf } from '../utils/http-header';

const authProvider = {
    login: async ({ username, password }) => {
        const headers = await withAcsrf(httpHeader());
        const request = new Request(baseUrl.baseUrl + '/login', {
            method: 'POST',
            body: JSON.stringify({ email: username, password }),
            headers,
        });
        await fetch(request)
            .then(async response => {
                const data = await response.json();
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(data.message);
                }
                localStorage.setItem('userId', JSON.stringify(data.id));
                return data;
            })
            .then(auth => {
                localStorage.setItem('auth', JSON.stringify(auth));
                window.location.reload(false);
                /**
                 * @NOTE
                 * I mocked it for test
                 */
                //  const { token } = auth
                // const decodedToken = decodeJwt(token);
                // localStorage.setItem('token', token);
                // Should be: => localStorage.setItem('permissions', decodedToken.permissions);
                localStorage.setItem('permissions', ['super_admin']);
            })
            .catch(error => {
                throw new Error(error);
            });
    },
    checkError: error => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject({ message: false });
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () =>
        localStorage.getItem('auth')
            ? Promise.resolve()
            : Promise.reject({ message: 'login.required' }),
    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    getIdentity: () => {
        try {
            const { id, fullName, avatar } = JSON.parse(
                localStorage.getItem('auth')
            );
            return Promise.resolve({ id, fullName, avatar });
        } catch (error) {
            return Promise.reject(error);
        }
    },
    // authorization
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    },
};

export default authProvider;
