import * as React from 'react';
import { useState } from 'react';
import {
    useNotify,
    useRedirect,
    Button,
    useRecordContext,
    useRefresh,
} from 'react-admin';
import BlockIcon from '@mui/icons-material/Block';
import baseUrl from '../../../enums/base-url.enum';
import httpHeader, { withAcsrf } from '../../../utils/http-header';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ErrorHandler from '../../../utils/error-handler';

const SuspendSmaButton = ({ disabled, name }) => {
    const record = useRecordContext();
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();
    const [loading, setLoading] = useState(false);
    let deactivate;
    if (disabled) {
        deactivate = true;
    } else {
        deactivate = loading;
    }

    const handleClick = async () => {
        setLoading(true);
        fetch(baseUrl.baseUrl + `/sma/${record.id}/suspend`, {
            method: 'POST',
            headers: await withAcsrf(httpHeader()),
        })
            .then(data => {
                data.json()
                    .then(function (data) {
                        if (data.status === 'error') {
                            ErrorHandler(data, redirect, notify);
                            notify(`${data.message}`, { type: 'warning' });
                        }
                        notify('User Suspended!', { type: 'success' });
                        redirect('/sma');
                        refresh();
                    })
                    .catch(function (e) {
                        notify(e);
                        redirect('/sma');
                    });
            })
            .catch(e => {
                deactivate = false;
                notify('Error: user suspension failed', 'warning');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const submit = () => {
        confirmAlert({
            title: 'Confirm to suspend',
            message: 'Are you sure to suspend current admin ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleClick(),
                },
                {
                    label: 'No',
                    onClick: () => redirect('/sma'),
                },
            ],
        });
    };
    return (
        <Button
            label={name}
            icon={BlockIcon}
            onClick={submit}
            disabled={deactivate}
        >
            <BlockIcon />
        </Button>
    );
};

export default SuspendSmaButton;
