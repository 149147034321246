import { stringify } from 'query-string';
import baseUrl from '../enums/base-url.enum';
import httpClient from '../utils/http-client';
import httpHeader, { withAcsrf } from '../utils/http-header';
const apiUrl = baseUrl.baseUrl;

const provider = {
    getList: async (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const timezoneOffset = -1 * new Date().getTimezoneOffset();
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
            timeZoneOffset: JSON.stringify(timezoneOffset),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        const { json } = await httpClient(url);
        return {
            data: json.data,
            total: json.paging.total,
        };
    },

    getOne: async (resource, params) => {
        const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}`);
        return {
            data: json,
        };
    },

    getMany: async (resource, params) => {
        const query = {
            filter: JSON.stringify({ ids: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        const { json } = await httpClient(url);
        return { data: json };
    },

    getManyReference: async (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        const { headers, json } = httpClient(url);
        return {
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        };
    },

    create: async (resource, params) => {
        const { json } = await httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        });
        return {
            data: { ...params.data, id: json.id },
        };
    },

    update: async (resource, params) => {
        const { json } = await httpClient(
            `${apiUrl}/${resource}/${params.id}`,
            {
                method: 'PATCH',
                body: JSON.stringify(params.data),
            }
        );
        return { data: json };
    },

    updateMany: async (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const { json } = await httpClient(
            `${apiUrl}/${resource}?${stringify(query)}`,
            {
                method: 'PUT',
                body: JSON.stringify(params.data),
            }
        );
        return { data: json };
    },

    delete: async (resource, params) => {
        const { json } = await httpClient(
            `${apiUrl}/${resource}/${params.id}`,
            {
                method: 'DELETE',
            }
        );
        return { data: json };
    },

    deleteMany: async (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const { json } = await httpClient(
            `${apiUrl}/${resource}?${stringify(query)}`,
            {
                method: 'DELETE',
                body: JSON.stringify(params.data),
            }
        );
        return { data: json };
    },

    verifyUser: async (userId, data) => {
        const response = await fetch(`${apiUrl}/users/${userId}/verify`, {
            headers: await withAcsrf(httpHeader()),
            method: 'POST',
            body: { ...data },
        });
        return response.json();
    },
};

export default provider;
