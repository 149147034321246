import * as React from 'react';
import {
    SimpleForm,
    TextInput,
    Toolbar,
    useRedirect,
    useRefresh,
    required,
    useNotify,
    SaveButton,
    minLength,
    maxLength,
} from 'react-admin';
import { Typography } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import baseUrl from '../../../enums/base-url.enum';
import httpHeader, { withAcsrf } from '../../../utils/http-header';
import { useState } from 'react';

import userVerificationStatusEnum from '../../../enums/user-verification-status.enum';
import userVerificationTypeEnum from '../../../enums/user-verification-type.enum';
import { RefreshSharp } from '@mui/icons-material';

const VerifyComponent = props => {
    const record = props.data;
    const [loading, setLoading] = useState(false);
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();

    let verificationTypeData;

    const handleConfirm = async data => {
        setLoading(true);
        try {
            const response = await fetch(
                baseUrl.baseUrl + `/user/confirm-verification`,
                {
                    method: 'POST',
                    headers: await withAcsrf(httpHeader()),
                    body: JSON.stringify({
                        adminApproveDescription: data.adminApproveDescription,
                        userVerificationType:
                            verificationTypeData.verificationType,
                        userId: record.id,
                    }),
                }
            );
            if (response.ok) {
                redirect(`/user/${record.id}`);
                refresh();
                notify('Verification successfully confirmed!', {
                    type: 'success',
                });
            } else {
                notify('verfication confirmation failed!', { type: 'warning' });
                redirect('/user');
            }
        } catch (err) {
            notify('verfication confirmation failed!', { type: 'warning' });
            redirect('/user');
        } finally {
            setLoading(false);
        }
    };

    const handleAlert = event => {
        confirmAlert({
            title: `Confirm Manual Verification`,
            message: `Are you sure to set ${verificationTypeData.verificationType} to verified for ${record.firstName} ${record.lastName} user`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleConfirm(event),
                },
                {
                    label: 'No',
                    onClick: () => {
                        redirect(`/user/${record.id}`);
                        refresh();
                    },
                },
            ],
        });
    };

    const kycData = record.userVerification?.find(
        item =>
            item.verificationType === userVerificationTypeEnum.KYC &&
            item.verificationStatus ===
                userVerificationStatusEnum.VerificationNotPassed
    );
    const amlIndividualData = record.userVerification?.find(
        item =>
            item.verificationType === userVerificationTypeEnum.AML_Individual &&
            item.verificationStatus ===
                userVerificationStatusEnum.VerificationNotPassed
    );

    verificationTypeData = kycData || amlIndividualData;

    const VerifyUserToolbar = props => {
        return (
            <Toolbar {...props}>
                <SaveButton
                    label={'Confirm'}
                    type="button"
                    mutationOptions={{
                        onSuccess: e => handleAlert(e),
                    }}
                />
            </Toolbar>
        );
    };

    const VerifyUserForm = props => (
        <div
            style={{
                marginLeft: 'auto',
                borderLeft: '2px solid',
                borderColor: '#a9a9a938',
            }}
        >
            <SimpleForm toolbar={<VerifyUserToolbar {...props} />}>
                <Typography variant="h6" gutterBottom style={{ te: '20px' }}>
                    {`Verify User ${verificationTypeData.verificationType}`}
                </Typography>
                <Typography
                    variant="subtitle1"
                    style={{ marginTop: '20px', maxWidth: '300px' }}
                >
                    {`User ${verificationTypeData.verificationType} verification is failed with reason "${verificationTypeData.failReason}"`}
                </Typography>
                <TextInput
                    style={{ marginTop: '20px' }}
                    label="Verification Description"
                    source="adminApproveDescription"
                    validate={(required(), minLength(3), maxLength(100))}
                />
            </SimpleForm>
        </div>
    );

    return verificationTypeData && <VerifyUserForm />;
};

export default VerifyComponent;
