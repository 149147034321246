import * as React from 'react';
import {
    List,
    TextInput,
    Filter,
    ResourceContextProvider,
    DateInput,
    SelectInput,
    useListContext,
} from 'react-admin';
import resourceEnum from '../../enums/resource.enum';
import { Pagination } from 'react-admin';
import withStyles from '@mui/styles/withStyles';
import RapcListTabbedDatagrid from './custom-pages/rapc-list-tabbed-datagrid';
import userStatusEnum from '../../enums/user-status.enum';

const RapcPagination = () => <Pagination rowsPerPageOptions={[10, 50, 100]} />;

const datagridStyles = {
    total: { fontWeight: 'bold', backgroundColor: 'grey' },
};

const StyledTabbedDatagrid = withStyles(datagridStyles)(RapcListTabbedDatagrid);

const userStatusChoices = [
    { name: 'Active', id: 'active' },
    { name: 'Invited', id: 'invited' },
    { name: 'Suspended', id: 'suspended' },
    { name: 'Deactivated', id: 'deactivated' },
];

const rapcOnboardingStatusChoices = [
    { name: 'TempPassword', id: 'tempPassword' },
    { name: 'UserProfile', id: 'userProfile' },
    { name: 'TwoFA', id: 'twoFA' },
];

const RapcFilter = props => {
    const context = useListContext();
    let filteredUserStatusChoices = [...userStatusChoices];
    if (
        context.filterValues &&
        context.filterValues.status !== userStatusEnum.Deactivated
    ) {
        filteredUserStatusChoices = filteredUserStatusChoices.filter(
            item => item.id !== userStatusEnum.Deactivated
        );
    }
    if (context?.filterValues?.status === userStatusEnum.Deactivated) {
        return (
            <Filter {...props}>
                <TextInput label="Search" source="q" alwaysOn />
                <DateInput
                    source="deactivatedFrom"
                    label="Deactivated From"
                    alwaysOn
                />
                <DateInput
                    source="deactivatedTill"
                    label="Deactivated Till"
                    alwaysOn
                />
                <DateInput source="createdFrom" label="Created From" alwaysOn />
                <DateInput source="createdTill" label="Created Till" alwaysOn />
                <SelectInput
                    source="onboardingStatus"
                    choices={rapcOnboardingStatusChoices}
                    alwaysOn
                />
            </Filter>
        );
    }
    return (
        <Filter {...props}>
            <TextInput label="Search" source="q" alwaysOn />
            <DateInput source="createdFrom" label="Created From" alwaysOn />
            <DateInput source="createdTill" label="Created Till" alwaysOn />
            <SelectInput
                source="status"
                choices={filteredUserStatusChoices}
                alwaysOn
                disabled={
                    context.filterValues &&
                    context.filterValues.status === userStatusEnum.Deactivated
                }
            />
            <SelectInput
                source="onboardingStatus"
                choices={rapcOnboardingStatusChoices}
                alwaysOn
            />
        </Filter>
    );
};

export const RapcList = props => {
    return (
        <ResourceContextProvider value={resourceEnum.rapc}>
            <List
                {...props}
                empty={false}
                filters={<RapcFilter />}
                pagination={<RapcPagination />}
            >
                <StyledTabbedDatagrid />
            </List>
        </ResourceContextProvider>
    );
};
