import * as React from 'react';
import { useState } from 'react';
import { useRedirect, Button, useRecordContext } from 'react-admin';
import ReplayIcon from '@mui/icons-material/Replay';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const InviteCompanyButton = ({ disabled, name }) => {
    const redirect = useRedirect();
    const [loading, setLoading] = useState(false);
    const record = useRecordContext();
    let deactivate;
    if (disabled) {
        deactivate = true;
    } else {
        deactivate = loading;
    }

    const handleClick = () => {
        setLoading(true);
        redirect(
            '/rapc-admin/invitation-business',
            null,
            null,
            {},
            { data: { id: record.businessId, rapcId: record.rapcId } }
        );
    };

    const submit = () => {
        confirmAlert({
            title: 'Confirm to invite',
            message: 'Are you sure to invite current company?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleClick(),
                },
                {
                    label: 'No',
                    onClick: () => redirect('/company'),
                },
            ],
        });
    };
    return (
        <Button
            label={name}
            icon={ReplayIcon}
            onClick={submit}
            disabled={deactivate}
        >
            <ReplayIcon />
        </Button>
    );
};

export default InviteCompanyButton;
