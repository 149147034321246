import * as React from 'react';
import { useState } from 'react';
import {
    useNotify,
    useRedirect,
    Button,
    useRecordContext,
    useRefresh,
} from 'react-admin';
import ReplayIcon from '@mui/icons-material/Replay';
import baseUrl from '../../../enums/base-url.enum';
import httpHeader from '../../../utils/http-header';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ErrorHandler from '../../../utils/error-handler';

const ReinviteUserButton = ({ disabled, name }) => {
    const record = useRecordContext();
    const redirect = useRedirect();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    let deactivate;
    if (disabled) {
        deactivate = true;
    } else {
        deactivate = loading;
    }

    const handleClick = () => {
        setLoading(true);
        fetch(baseUrl.baseUrl + `/user/${record.id}/regenerate-invitation`, {
            method: 'GET',
            headers: httpHeader(),
        })
            .then(data => {
                data.json()
                    .then(function (data) {
                        if (data.status === 'error') {
                            ErrorHandler(data, redirect, notify);
                            notify(`${data.message}`, { type: 'warning' });
                            return;
                        }
                        redirect(
                            '/user-custom/invitation/confirmation',
                            null,
                            null,
                            {},
                            { data }
                        );
                        notify('User Reinvited!', { type: 'success' });
                    })
                    .catch(function (e) {
                        notify(e);
                        redirect('/user');
                    });
            })
            .catch(e => {
                deactivate = false;
                notify('Error: user invitation failed', 'warning');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const submit = () => {
        confirmAlert({
            title: 'Confirm to reinvite',
            message: 'Are you sure to reinvite current user?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleClick(),
                },
                {
                    label: 'No',
                    onClick: () => redirect('/user'),
                },
            ],
        });
    };
    return (
        <Button
            label={name}
            icon={ReplayIcon}
            onClick={submit}
            disabled={deactivate}
        >
            <ReplayIcon />
        </Button>
    );
};

export default ReinviteUserButton;
