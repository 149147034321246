import {
    TextInput,
    SimpleForm,
    required,
    email,
    useRedirect,
    useNotify,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import baseUrl from '../../../enums/base-url.enum';
import httpHeader, { withAcsrf } from '../../../utils/http-header';
import { useState } from 'react';
import ErrorHandler from '../../../utils/error-handler';
import CorporateMailValidator from '../../../validators/corporate-mail-validator';
import isLowerCaseEmailValidator from '../../../validators/is-lowercase-email-validator';

const SMAInvitation = () => {
    const [loading, setLoading] = useState(false);
    const redirect = useRedirect();
    const notify = useNotify();
    const handleClick = async data => {
        setLoading(true);
        fetch(baseUrl.baseUrl + `/sma/invite`, {
            method: 'POST',
            headers: await withAcsrf(httpHeader()),
            body: JSON.stringify({
                email: data.email,
            }),
        })
            .then(data => {
                data.json()
                    .then(function (data) {
                        if (data.status === 'error') {
                            ErrorHandler(data, redirect, notify);
                            redirect('/sma-admin/invitation');
                            notify(`${data.message}`, { type: 'warning' });
                        } else {
                            redirect(
                                '/sma-admin/invitation/confirmation',
                                null,
                                null,
                                {},
                                { data }
                            );
                            notify('Admin successfully invited!', {
                                type: 'success',
                            });
                        }
                    })
                    .catch(function (e) {
                        notify(e);
                        redirect('sma-admin/invitation');
                    });
            })
            .catch(e => {
                setLoading(false);
                notify('Error: user suspension failed', 'warning');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSubmit = event => {
        confirmAlert({
            title: 'Confirm to Invite Secro Main Admin',
            message: `Are you sure to invite current admin  with mail ${event.email} ?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleClick(event),
                },
                {
                    label: 'No',
                    onClick: () => redirect(''),
                },
            ],
        });
    };

    return (
        <Box display="flex" my={2}>
            <Box flex={1}>
                <SimpleForm onSubmit={handleSubmit} sx={{ maxWidth: 500 }}>
                    <Typography variant="h6" gutterBottom>
                        Invite Secro main Admin
                    </Typography>
                    <TextInput
                        name="email"
                        validate={[
                            required(),
                            email(),
                            CorporateMailValidator,
                            isLowerCaseEmailValidator,
                        ]}
                        source="email"
                        fullWidth
                    />
                </SimpleForm>
            </Box>
        </Box>
    );
};

export default SMAInvitation;
