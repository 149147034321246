import * as React from 'react';
import {
    List,
    TextInput,
    Filter,
    ResourceContextProvider,
    DateInput,
    SelectInput,
    useListContext,
} from 'react-admin';
import resourceEnum from '../../enums/resource.enum';
import { Pagination } from 'react-admin';
import CompanyListActions from './components/company-list.action';
import TabbedDatagrid from './custom-pages/tabbed-datagrid';
import withStyles from '@mui/styles/withStyles';
import companyStatusEnum from '../../enums/company-status.enum';
import companyTypeEnum from '../../enums/company-type.enum';

const CompanyPagination = () => (
    <Pagination rowsPerPageOptions={[10, 50, 100]} />
);

const datagridStyles = {
    total: { fontWeight: 'bold', backgroundColor: 'grey' },
};

const StyledTabbedDatagrid = withStyles(datagridStyles)(TabbedDatagrid);

const companyStatusChoices = [
    { name: 'Invited', id: 'invited' },
    { name: 'Trusted', id: 'trusted' },
    { name: 'Unverified', id: 'unverified' },
    { name: 'Suspended', id: 'suspended' },
    { name: 'Deactivated', id: 'deactivated' },
];

const companyOnboardingStatusChoices = [
    { name: 'KYB-VerificationPassed', id: 'KYB-verificationPassed' },
    { name: 'KYB-VerifiedByAdmin', id: 'KYB-verifiedByAdmin' },
    { name: 'KYB-VerificationNotPassed', id: 'KYB-verificationNotPassed' },
    { name: 'AML-VerificationPassed', id: 'AML-verificationPassed' },
    { name: 'AML-VerifiedByAdmin', id: 'AML-verifiedByAdmin' },
    { name: 'AML-VerificationNotPassed', id: 'AML-verificationNotPassed' },
    { name: 'BAV-VerificationPassed', id: 'BAV-verificationPassed' },
    { name: 'BAV-VerifiedByAdmin', id: 'BAV-verifiedByAdmin' },
    { name: 'BAV-VerificationNotPassed', id: 'BAV-verificationNotPassed' },
];

const companyTypeChoices = [
    { name: 'Regular', id: 'regular' },
    { name: 'Indorsee', id: 'indorsee' },
    { name: 'Trading Partner', id: 'tradingPartner' },
];

const CompanyFilter = props => {
    const context = useListContext();
    let filteredCompanyStatusChoices = [...companyStatusChoices];
    let filteredCompanyTypeChoices = [...companyTypeChoices];

    const isOnTabDeactivated =
        context.filterValues && context.filterValues.tabName === 'deactivated';

    const isOnTabCustomerLeads =
        context.filterValues &&
        context.filterValues.tabName === 'customerLeads';

    if (
        context.filterValues &&
        context.filterValues.status !== companyStatusEnum.Deactivated
    ) {
        filteredCompanyStatusChoices = filteredCompanyStatusChoices.filter(
            item => item.id !== companyStatusEnum.Deactivated
        );
    }

    if (isOnTabCustomerLeads) {
        filteredCompanyTypeChoices = filteredCompanyTypeChoices.filter(
            item => item.id !== companyTypeEnum.Regular
        );
    }

    return (
        <Filter {...props}>
            <TextInput label="Search" source="q" alwaysOn />
            <DateInput source="createdFrom" label="Created From" alwaysOn />
            <DateInput source="createdTill" label="Created Till" alwaysOn />
            <SelectInput
                source="status"
                choices={filteredCompanyStatusChoices}
                alwaysOn
                disabled={isOnTabDeactivated || isOnTabCustomerLeads}
            />
            <SelectInput
                label="Onboarding Status"
                source="companyVerifications"
                choices={companyOnboardingStatusChoices}
                alwaysOn
                fullWidth
            />
            {isOnTabCustomerLeads && (
                <SelectInput
                    label="Company type"
                    source="type"
                    choices={filteredCompanyTypeChoices}
                    alwaysOn
                    fullWidth
                />
            )}
        </Filter>
    );
};

export const CompanyList = props => {
    return (
        <ResourceContextProvider value={resourceEnum.company}>
            <List
                {...props}
                hasCreate={true}
                empty={false}
                actions={<CompanyListActions />}
                filters={<CompanyFilter />}
                pagination={<CompanyPagination />}
            >
                <StyledTabbedDatagrid />
            </List>
        </ResourceContextProvider>
    );
};
