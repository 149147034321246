import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    SaveButton,
    minLength,
    maxLength,
    Toolbar,
    required,
} from 'react-admin';
import HumanNameValidator from '../../validators/human-name-validator';

const UserEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const SmaEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<UserEditToolbar />}>
            <TextInput source="id" disabled />
            <TextInput
                source="firstName"
                validate={[
                    required(),
                    minLength(3),
                    maxLength(30),
                    HumanNameValidator,
                ]}
            />
            <TextInput
                source="lastName"
                validate={[
                    required(),
                    minLength(3),
                    maxLength(30),
                    HumanNameValidator,
                ]}
            />
            <TextInput source="email" disabled />
            <TextInput source="status" disabled />
            <TextInput source="role" disabled />
            <DateInput source="createdAt" disabled />
        </SimpleForm>
    </Edit>
);

export default SmaEdit;
