import {
    TextInput,
    SimpleForm,
    required,
    useRedirect,
    useNotify,
    minLength,
    maxLength,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import 'react-confirm-alert/src/react-confirm-alert.css';
import baseUrl from '../../../enums/base-url.enum';
import httpHeader, { withAcsrf } from '../../../utils/http-header';
import { useState } from 'react';
import CountrySelectInput from '../../../custom-pages/country-select-input';
import JurisdictionCodeSelectInput from '../../../custom-pages/jurisdiction-code-select-input';
import PhoneNumberInput from '../../../custom-pages/phone-number-input';
import ErrorHandler from '../../../utils/error-handler';

const RAPCInvitationCompany = () => {
    const [loading, setLoading] = useState(false);
    const redirect = useRedirect();
    const notify = useNotify();
    const handleClick = async data => {
        setLoading(true);
        fetch(baseUrl.baseUrl + `/business`, {
            method: 'POST',
            headers: await withAcsrf(httpHeader()),
            body: JSON.stringify({
                businessName: data.businessName,
                taxRegistrationNumber: data.taxRegistrationNumber,
                ...(data.jurisdictionCode && {
                    jurisdictionCode: data.jurisdictionCode,
                }),
                phoneNumber: data.phoneNumber || null,
                ...(data.country && {
                    country: data.country,
                }),
                ...(data.address && {
                    address: data.address,
                }),
                ...(data.stateOrRegion && {
                    stateOrRegion: data.stateOrRegion,
                }),
            }),
        })
            .then(data => {
                data.json()
                    .then(function (data) {
                        if (data.status === 'error') {
                            ErrorHandler(data, redirect, notify);
                            redirect('/rapc-admin/invitation-company');
                            notify(`${data.message}`, { type: 'warning' });
                        } else {
                            redirect(
                                '/rapc-admin/invitation-business',
                                null,
                                null,
                                {},
                                { data }
                            );
                            notify('Business successfully created!', {
                                type: 'success',
                            });
                        }
                    })
                    .catch(function (e) {
                        notify(e);
                        redirect('/rapc-admin/invitation-company');
                    });
            })
            .catch(e => {
                setLoading(false);
                notify('Error: user suspension failed', 'warning');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Box display="flex" my={2}>
            <Box flex={1}>
                <SimpleForm onSubmit={handleClick} sx={{ maxWidth: 500 }}>
                    <Typography variant="h6" gutterBottom>
                        Invite RAPC Admin Company Info:
                    </Typography>
                    <TextInput
                        name="businessName"
                        validate={[required(), minLength(3), maxLength(80)]}
                        source="businessName"
                        fullWidth
                    />
                    <TextInput
                        name="taxRegistrationNumber"
                        validate={[required(), minLength(3), maxLength(100)]}
                        source="taxRegistrationNumber"
                        fullWidth
                    />
                    <TextInput
                        name="stateOrRegion"
                        source="stateOrRegion"
                        validate={[minLength(3), maxLength(100)]}
                        fullWidth
                    />
                    <TextInput
                        name="address"
                        source="address"
                        validate={[minLength(3), maxLength(300)]}
                        fullWidth
                    />
                    <PhoneNumberInput fullWidth source="phoneNumber" />
                    <br />
                    <JurisdictionCodeSelectInput
                        name="jurisdictionCode"
                        source="jurisdictionCode"
                        fullWidth
                    />
                    <CountrySelectInput
                        name="country"
                        validate={[required()]}
                        source="country"
                        fullWidth
                    />
                </SimpleForm>
            </Box>
        </Box>
    );
};

export default RAPCInvitationCompany;
