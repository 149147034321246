const HumanNameValidator = value => {
    const isValid = /^[a-z A-Z,.'-]+$/.test(value);

    if (isValid) {
        return undefined;
    }
    return 'Please enter valid human name!';
};

export default HumanNameValidator;
