import * as React from 'react';
import {
    SimpleForm,
    TextInput,
    Toolbar,
    useRedirect,
    useRefresh,
    required,
    useNotify,
    SaveButton,
    minLength,
    maxLength,
} from 'react-admin';
import { Typography } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import baseUrl from '../../../enums/base-url.enum';
import httpHeader, { withAcsrf } from '../../../utils/http-header';
import { useState } from 'react';

import companyVerificationStatusEnum from '../../../enums/company-verification-status.enum';
import companyVerificationTypeEnum from '../../../enums/company-verification-type.enum';
import { RefreshSharp } from '@mui/icons-material';

const VerifyComponent = props => {
    const record = props.data;
    const [loading, setLoading] = useState(false);
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();

    let verificationTypeData;

    const handleConfirm = async data => {
        setLoading(true);
        try {
            const response = await fetch(
                baseUrl.baseUrl + `/company/confirm-verification`,
                {
                    method: 'PUT',
                    headers: await withAcsrf(httpHeader()),
                    body: JSON.stringify({
                        adminApproveDescription: data.adminApproveDescription,
                        companyVerificationType:
                            verificationTypeData.verificationType,
                        companyId: record.id,
                    }),
                }
            );
            if (response.ok) {
                redirect(`/company/${record.id}`);
                refresh();
                notify('Verification successfully confirmed!', {
                    type: 'success',
                });
            } else {
                notify('verfication confirmation failed!', { type: 'warning' });
                redirect('/company');
            }
        } catch (err) {
            notify('verfication confirmation failed!', { type: 'warning' });
            redirect('/company');
        } finally {
            setLoading(false);
        }
    };

    const handleAlert = event => {
        confirmAlert({
            title: `Confirm Manual Verification`,
            message: `Are you sure to set ${verificationTypeData.verificationType} to verified for ${record.companyName} company`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleConfirm(event),
                },
                {
                    label: 'No',
                    onClick: () => {
                        redirect(`/company/${record.id}`);
                        refresh();
                    },
                },
            ],
        });
    };

    const kybdata = record.companyVerifications?.find(
        item =>
            item.verificationType === companyVerificationTypeEnum.KYB &&
            item.verificationStatus ===
                companyVerificationStatusEnum.VerificationNotPassed
    );
    const amlData = record.companyVerifications?.find(
        item =>
            item.verificationType === companyVerificationTypeEnum.AML &&
            item.verificationStatus ===
                companyVerificationStatusEnum.VerificationNotPassed
    );
    const bavData = record.companyVerifications?.find(
        item =>
            item.verificationType === companyVerificationTypeEnum.BAV &&
            item.verificationStatus ===
                companyVerificationStatusEnum.VerificationNotPassed
    );

    verificationTypeData = kybdata || amlData || bavData;

    const VerifyCompanyToolbar = props => {
        return (
            <Toolbar {...props}>
                <SaveButton
                    label={'Confirm'}
                    type="button"
                    mutationOptions={{
                        onSuccess: e => handleAlert(e),
                    }}
                />
            </Toolbar>
        );
    };

    const VerifyCompanyForm = props => (
        <div
            style={{
                marginLeft: 'auto',
                borderLeft: '2px solid',
                borderColor: '#a9a9a938',
            }}
        >
            <SimpleForm toolbar={<VerifyCompanyToolbar {...props} />}>
                <Typography variant="h6" gutterBottom style={{ te: '20px' }}>
                    {`Verify Company ${verificationTypeData.verificationType}`}
                </Typography>
                <Typography
                    variant="subtitle1"
                    style={{ marginTop: '20px', maxWidth: '300px' }}
                >
                    {`Company ${verificationTypeData.verificationType} verification is failed with reason "${verificationTypeData.failReason}"`}
                </Typography>
                <TextInput
                    style={{ marginTop: '20px' }}
                    label="Verification Description"
                    source="adminApproveDescription"
                    validate={(required(), minLength(3), maxLength(100))}
                />
            </SimpleForm>
        </div>
    );

    return verificationTypeData && <VerifyCompanyForm />;
};

export default VerifyComponent;
