import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    SaveButton,
    Toolbar,
    useRecordContext,
    Button,
    useRedirect,
    useRefresh,
    minLength,
    maxLength,
    required,
    email,
} from 'react-admin';

import CancelIcon from '@mui/icons-material/Cancel';
import userVerificationStatusEnum from '../../enums/user-verification-status.enum';
import userVerificationTypeEnum from '../../enums/user-verification-type.enum';
import userOnboardingStatusEnum from '../../enums/user-onboarding-status.enum';
import PhoneNumberInput from '../../custom-pages/phone-number-input';
import HumanNameValidator from '../../validators/human-name-validator';

const transformRapc = data => ({
    ...data,
    phoneNumber: data.phoneNumber || null,
});

const RapcEditToolbar = props => {
    const redirect = useRedirect();
    const refresh = useRefresh();
    return (
        <Toolbar {...props}>
            <SaveButton type="button" transform={transformRapc} />
            <div style={{ margin: '1em' }}>
                <Button
                    label={'Cancel'}
                    icon={CancelIcon}
                    onClick={() => {
                        redirect(`/rapc`);
                        refresh();
                    }}
                    variant="contained"
                    size="medium"
                >
                    {' '}
                    <CancelIcon />
                </Button>
            </div>
        </Toolbar>
    );
};

const RapcEditForm = props => {
    const record = useRecordContext();

    const isKycPassed = record.userVerification?.some(
        item =>
            item.verificationType === userVerificationTypeEnum.KYC &&
            item.verificationStatus ===
                userVerificationStatusEnum.VerificationPassed
    );

    const isOtpPassed = ![
        userOnboardingStatusEnum.companyProfile,
        userOnboardingStatusEnum.tempPassword,
        userOnboardingStatusEnum.userProfile,
        userOnboardingStatusEnum.twoFA,
    ].includes(record.onboardingStatus);

    return (
        <div style={{ display: 'flex' }}>
            <SimpleForm toolbar={<RapcEditToolbar />}>
                <TextInput source="id" disabled fullWidth />
                <TextInput
                    source="firstName"
                    disabled={isKycPassed}
                    validate={[
                        required(),
                        minLength(3),
                        maxLength(30),
                        HumanNameValidator,
                    ]}
                    fullWidth
                />
                <TextInput
                    source="lastName"
                    disabled={isKycPassed}
                    validate={[
                        required(),
                        minLength(3),
                        maxLength(30),
                        HumanNameValidator,
                    ]}
                    fullWidth
                />
                <PhoneNumberInput
                    disabled={isOtpPassed}
                    fullWidth
                    source="phoneNumber"
                />
                <TextInput
                    source="email"
                    fullWidth
                    disabled
                    validate={[required(), email()]}
                />
                <TextInput source="status" disabled fullWidth />
                <DateInput source="createdAt" disabled fullWidth />
                <TextInput source="role" disabled fullWidth />
                <TextInput source="company" disabled fullWidth />
            </SimpleForm>
        </div>
    );
};

const RapcEdit = props => (
    <Edit {...props}>
        <RapcEditForm {...props} />
    </Edit>
);

export default RapcEdit;
