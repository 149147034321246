const ErrorHandler = (data, redirect, notify) => {
    const { statusCode } = data.meta;
    switch (statusCode) {
        case 401: {
            notify('unauthorized!');
            localStorage.removeItem('auth');
            redirect('/');
            break;
        }
        case 500:
            // internal error page
            break;
        case 422:
            // proper actions maybe based on shortCode
            break;
        default:
        // code block
    }
};

export default ErrorHandler;
