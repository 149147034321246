// in src/Settings.js
import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import { Title } from 'react-admin';

const Settings = () => (
    <Card>
        <Title title="My Page" />
        <CardContent>... Settings page!</CardContent>
    </Card>
);

export default Settings;
