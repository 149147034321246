import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    SaveButton,
    Toolbar,
    useRecordContext,
    Button,
    useRedirect,
    useRefresh,
    minLength,
    maxLength,
    required,
} from 'react-admin';
import companyVerificationStatusEnum from '../../enums/company-verification-status.enum';
import companyVerificationTypeEnum from '../../enums/company-verification-type.enum';
import companyStatusEnum from '../../enums/company-status.enum';
import CancelIcon from '@mui/icons-material/Cancel';
import VerifyComponent from './custom-pages/company-verifycation-confirm';
import CountrySelectInput from '../../custom-pages/country-select-input';
import JurisdictionCodeSelectInput from '../../custom-pages/jurisdiction-code-select-input';
import PhoneNumberInput from '../../custom-pages/phone-number-input';

const transformCompany = data => ({
    ...data,
    phoneNumber: data.phoneNumber || null,
});

const CompanyEditToolbar = props => {
    const redirect = useRedirect();
    const refresh = useRefresh();
    return (
        <Toolbar {...props}>
            <SaveButton type="button" transform={transformCompany} />
            <div style={{ margin: '1em' }}>
                <Button
                    label={'Cancel'}
                    icon={CancelIcon}
                    onClick={() => {
                        redirect(`/company`);
                        refresh();
                    }}
                    variant="contained"
                    size="medium"
                >
                    {' '}
                    <CancelIcon />
                </Button>
            </div>
        </Toolbar>
    );
};

const CompanyEditForm = props => {
    const record = useRecordContext();
    const isKybPassed = record.companyVerifications?.some(
        item =>
            item.verificationType === companyVerificationTypeEnum.KYB &&
            item.verificationStatus ===
                companyVerificationStatusEnum.VerificationPassed
    );
    const isVerificationConfirmationAllowed = ![
        companyStatusEnum.Blocked,
        companyStatusEnum.Deleted,
        companyStatusEnum.Suspended,
        companyStatusEnum.Deactivated,
        companyStatusEnum.Trusted,
    ].includes(record.status);
    const convertEmptyStringToUndefined = v => (v === '' ? null : v);

    return (
        <div style={{ display: 'flex' }}>
            <SimpleForm toolbar={<CompanyEditToolbar />}>
                <TextInput source="id" disabled fullWidth />
                <TextInput
                    source="companyName"
                    validate={[required(), minLength(3), maxLength(80)]}
                    fullWidth
                />
                <TextInput source="status" disabled fullWidth />
                <TextInput source="rapcFirstName" disabled fullWidth />
                <TextInput source="rapcLastName" disabled fullWidth />
                <TextInput source="rapcMail" disabled fullWidth />
                <TextInput source="rapcPhoneNumber" disabled fullWidth />
                <TextInput
                    name="taxRegistrationNumber"
                    validate={[required()]}
                    source="taxRegistrationNumber"
                    fullWidth
                    disabled={isKybPassed}
                />
                <TextInput
                    name="stateOrRegion"
                    source="stateOrRegion"
                    validate={[minLength(3), maxLength(100)]}
                    fullWidth
                    parse={convertEmptyStringToUndefined}
                />
                <TextInput
                    name="address"
                    source="address"
                    validate={[minLength(3), maxLength(300)]}
                    fullWidth
                    parse={convertEmptyStringToUndefined}
                />
                <PhoneNumberInput fullWidth source="phoneNumber" />
                <br />
                <JurisdictionCodeSelectInput
                    name="jurisdictionCode"
                    source="jurisdictionCode"
                    fullWidth
                    disabled={isKybPassed}
                />
                <CountrySelectInput
                    name="country"
                    source="country"
                    validate={[required()]}
                    fullWidth
                    parse={convertEmptyStringToUndefined}
                />
                <DateInput source="createdAt" disabled fullWidth />
            </SimpleForm>
            {isVerificationConfirmationAllowed && (
                <VerifyComponent data={record} />
            )}
        </div>
    );
};

const CompanyEdit = props => (
    <Edit {...props}>
        <CompanyEditForm {...props} />
    </Edit>
);

export default CompanyEdit;
