import * as React from 'react';
import {
    useNotify,
    useRedirect,
    Button,
    useRecordContext,
    useRefresh,
    useDelete,
} from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import { confirmAlert } from 'react-confirm-alert';

const DeleteRowButton = ({ recource }) => {
    const record = useRecordContext();
    const [deleteOne, { isLoading, error }] = useDelete(undefined, undefined, {
        onError: error => {
            notify(`${error.message}`, { type: 'warning' });
        },
    });
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleClick = () => {
        deleteOne(recource, { id: record.id, previousData: record });
        if (error) {
            notify(`Error: ${recource} deletion failed`, 'warning');
            return redirect(`/${recource}`);
        }
        notify(`${recource} Deleted!`, { type: 'success' });
        redirect(`/${recource}`);
        refresh();
    };

    const submit = () => {
        confirmAlert({
            title: 'Confirm to delete',
            message: `Are you sure to delete current ${recource} ?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleClick(),
                },
                {
                    label: 'No',
                    onClick: () => redirect(`/${recource}`),
                },
            ],
        });
    };

    return (
        <Button
            label={'Delete'}
            icon={DeleteIcon}
            onClick={submit}
            disabled={isLoading}
            color="secondary"
        >
            <DeleteIcon />
        </Button>
    );
};

export default DeleteRowButton;
