import * as React from 'react';
import {
    List,
    TextInput,
    Filter,
    ResourceContextProvider,
    DateInput,
    SelectInput,
    useListContext,
} from 'react-admin';
import UserListActions from './components/user-list.action';
import { Pagination } from 'react-admin';
import withStyles from '@mui/styles/withStyles';
import UserListTabbedDatagrid from './custom-pages/user-list-tabbed-datagrid';
import userStatusEnum from '../../enums/user-status.enum';
import resourceEnum from '../../enums/resource.enum';

const UserPagination = () => <Pagination rowsPerPageOptions={[10, 50, 100]} />;

const datagridStyles = {
    total: { fontWeight: 'bold', backgroundColor: 'grey' },
};

const StyledTabbedDatagrid = withStyles(datagridStyles)(UserListTabbedDatagrid);

const userStatusChoices = [
    { name: 'Active', id: 'active' },
    { name: 'Invited', id: 'invited' },
    { name: 'Suspended', id: 'suspended' },
    { name: 'Deactivated', id: 'deactivated' },
];

const userRoleChoices = [
    { name: 'CompanyOfficer', id: 'companyOfficer' },
    { name: 'ContractManager', id: 'contractManager' },
    { name: 'ExternalUser', id: 'externalUser' },
];

const userOnboardingStatusChoices = [
    { name: 'TempPassword', id: 'tempPassword' },
    { name: 'UserProfile', id: 'userProfile' },
    { name: 'TwoFA', id: 'twoFA' },
    { name: 'KycCheck', id: 'kycCheck' },
    { name: 'AmlBusinessCheck', id: 'amlBusinessCheck' },
];

const UserFilter = props => {
    const context = useListContext();
    let filteredUserStatusChoices = [...userStatusChoices];
    if (
        context.filterValues &&
        context.filterValues.status !== userStatusEnum.Deactivated
    ) {
        filteredUserStatusChoices = filteredUserStatusChoices.filter(
            item => item.id !== userStatusEnum.Deactivated
        );
    }

    return (
        <Filter {...props}>
            <TextInput label="Search" source="q" alwaysOn />
            <DateInput source="createdFrom" label="Created From" alwaysOn />
            <DateInput source="createdTill" label="Created Till" alwaysOn />
            {context.filterValues.status !== userStatusEnum.Deactivated && (
                <SelectInput
                    source="status"
                    choices={filteredUserStatusChoices}
                    alwaysOn
                    disabled={
                        context.filterValues &&
                        context.filterValues.status ===
                            userStatusEnum.Deactivated
                    }
                />
            )}
            <SelectInput source="role" choices={userRoleChoices} alwaysOn />
            <SelectInput
                source="onboardingStatus"
                choices={userOnboardingStatusChoices}
                alwaysOn
            />
        </Filter>
    );
};

export const UserList = props => {
    return (
        <ResourceContextProvider value={resourceEnum.user}>
            <List
                empty={false}
                {...props}
                filters={<UserFilter />}
                pagination={<UserPagination />}
                actions={<UserListActions />}
            >
                <StyledTabbedDatagrid />
            </List>
        </ResourceContextProvider>
    );
};
