import { fetchUtils } from 'react-admin';
import httpHeader, { withAcsrf } from '../utils/http-header';

const httpClient = async (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers();
    }

    const standardHeaders = await withAcsrf(httpHeader());
    standardHeaders.forEach((value, key) => options.headers.set(key, value));
    return fetchUtils.fetchJson(url, options);
};

export default httpClient;
