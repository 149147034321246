import * as React from 'react';
import { useState } from 'react';
import {
    useNotify,
    useRedirect,
    Button,
    useRecordContext,
    useRefresh,
} from 'react-admin';
import baseUrl from '../../../enums/base-url.enum';
import httpHeader, { withAcsrf } from '../../../utils/http-header';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import BlockIcon from '@mui/icons-material/Block';
import companyStatusEnum from '../../../enums/company-status.enum';
import ErrorHandler from '../../../utils/error-handler';

const DeleteCompanyButton = ({ disabled, name }) => {
    const record = useRecordContext();
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();
    const [loading, setLoading] = useState(false);
    let deactivate;
    if (disabled) {
        deactivate = true;
    } else {
        deactivate = loading;
    }

    const handleClick = async () => {
        setLoading(true);
        fetch(baseUrl.baseUrl + `/company/${record.id}`, {
            method: 'DELETE',
            headers: await withAcsrf(httpHeader()),
        })
            .then(response => {
                if (response.status === 200) {
                    notify('Company Deleted!', { type: 'success' });
                    redirect('/company');
                    refresh();
                    return;
                }
                response.json().then(data => {
                    if (data.status === 'error') {
                        ErrorHandler(data, redirect, notify);
                        notify(`${data.message}`, { type: 'warning' });
                        redirect('/company');
                        return;
                    }
                });
            })
            .catch(e => {
                deactivate = false;
                notify('Error: rapc deletion failed', 'warning');
                redirect('/company');
                return;
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const submit = () => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to delete current company?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleClick(),
                },
                {
                    label: 'No',
                    onClick: () => redirect('/company'),
                },
            ],
        });
    };
    return (
        record.status !== companyStatusEnum.Deactivated && (
            <Button
                label={name}
                icon={BlockIcon}
                onClick={submit}
                disabled={deactivate}
            >
                <BlockIcon />
            </Button>
        )
    );
};

export default DeleteCompanyButton;
