import { SelectInput } from 'react-admin';
import { useState, useEffect } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import baseUrl from '../enums/base-url.enum';

// NOTE: we can add loading component in future
// const [loading, setLoading] = useState(true);
// setLoading(false);
const JurisdictionCodeSelectInput = props => {
    const [jurisdictionCode, setJurisdictionCode] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const neededBaseUrl = baseUrl.baseUrl.split('admin')[0];
            fetch(neededBaseUrl + `dictionary/jurisdiction-codes`, {
                method: 'GET',
            }).then(data => {
                data.json().then(function (data) {
                    setJurisdictionCode(data.JurisdictionCodes);
                });
            });
        }

        fetchData();
    }, []);

    return (
        <SelectInput
            {...props}
            optionText="name"
            optionValue="code"
            source="jurisdictionCode"
            choices={jurisdictionCode}
        />
    );
};

export default JurisdictionCodeSelectInput;
