import { SelectInput } from 'react-admin';
import 'react-confirm-alert/src/react-confirm-alert.css';
import userRoleEnum from '../enums/user-role.enum';

const UserRoleSelectInput = props => {
    const userRoles = [
        {
            name: 'Company Officer',
            value: userRoleEnum.CompanyOfficer,
        },
        {
            name: 'Contract Manager',
            value: userRoleEnum.ContractManager,
        },
    ];

    return (
        <SelectInput
            {...props}
            optionText="name"
            optionValue="value"
            source="role"
            choices={userRoles}
        />
    );
};

export default UserRoleSelectInput;
