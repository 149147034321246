import React, { Fragment, useState } from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    useListContext,
    EmailField,
    ArrayField,
    SimpleList,
    useRecordContext,
} from 'react-admin';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import RestoreAccessCompanyButton from '../components/restore-access-company-button';
import SuspendCompanyButton from '../components/suspend-access-company-button';
import DeleteCompanyButton from '../components/delete-company-button';
import companyStatusEnum from '../../../enums/company-status.enum';
import userStatusEnum from '../../../enums/user-status.enum';
import ReinviteCompanyButton from '../components/reinvite-company-button';
import companyTypeEnum from '../../../enums/company-type.enum';
import InviteCompanyButton from '../components/invite-company-button';

let isTradingPartner;

const ConditionalButtons = () => {
    const record = useRecordContext();

    isTradingPartner =
        record.status === companyStatusEnum.Invited &&
        (record.type === companyTypeEnum.Indorsee ||
            record.type === companyTypeEnum.TradingPartner);

    if (
        record.status === companyStatusEnum.Invited &&
        record.type === companyTypeEnum.Regular
    ) {
        return (
            <>
                <ReinviteCompanyButton disabled={false} name={'reinvite'} />
            </>
        );
    }

    if (isTradingPartner) {
        return (
            <>
                <InviteCompanyButton
                    disabled={record.rapcId !== 1}
                    name={'Invite as Regular Account'}
                />
            </>
        );
    }

    if (
        record.status === companyStatusEnum.Suspended ||
        record.status === companyStatusEnum.Deactivated
    ) {
        return (
            <>
                <RestoreAccessCompanyButton
                    disabled={false}
                    name={'restore access'}
                />
            </>
        );
    }

    if (
        record.status === companyStatusEnum.Trusted ||
        record.status === companyStatusEnum.Unverified
    ) {
        return (
            <>
                <SuspendCompanyButton disabled={false} name={'suspend'} />
                <DeleteCompanyButton
                    disabled={isTradingPartner}
                    name={'Delete'}
                />
            </>
        );
    }
};

const TabbedDatagrid = props => {
    const context = useListContext();

    const [tabValue, setTabValue] = useState(() => {
        switch (true) {
            case context.filterValues &&
                context.filterValues.status === companyStatusEnum.Deactivated:
                return 'deactivated';
            case context.filterValues && isTradingPartner:
                return 'customerLeads';
            default:
                return 'active';
        }
    });

    const tabs = [
        { id: 'active', name: 'Active' },
        { id: 'deactivated', name: 'Deactivated' },
        { id: 'customerLeads', name: 'Customer leads' },
    ];

    const handleChange = (event, value) => {
        switch (value) {
            case 'deactivated':
                setTabValue(value);
                context.setFilters({
                    ...context.filterValues,
                    status: companyStatusEnum.Deactivated,
                    type: null,
                    tabName: value,
                });
                break;
            case 'customerLeads':
                setTabValue(value);
                context.setFilters({
                    ...context.filterValues,
                    status: companyStatusEnum.Invited,
                    type: [
                        companyTypeEnum.Indorsee,
                        companyTypeEnum.TradingPartner,
                    ],
                    tabName: value,
                });
                break;
            default:
                setTabValue(value);
                context.setFilters({
                    ...context.filterValues,
                    status: null,
                    type: companyTypeEnum.Regular,
                    tabName: value,
                });
                break;
        }
    };

    return (
        <Fragment>
            <Tabs
                centered
                value={tabValue}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={choice.name}
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider />
            <Datagrid {...props} rowClick="edit">
                <TextField source="id" />
                <TextField source="companyName" />
                <TextField source="rapcFirstName" />
                <TextField source="rapcLastName" />
                <TextField source="status" />
                <ArrayField
                    sortable={false}
                    label="Onboarding Statuses"
                    source="companyVerifications"
                >
                    <SimpleList
                        primaryText={record => `${record.verificationType}-`}
                        secondaryText={record => `${record.verificationStatus}`}
                        rowStyle={record => ({
                            '& div': {
                                display: 'flex',
                                border: '3px purple solid',
                            },
                            border: '3px lightgray groove',
                            borderRadius: '50px',
                            height: '50px',
                        })}
                    />
                </ArrayField>
                <EmailField source="rapcEmail" />
                <TextField source="taxRegistrationNumber" />
                <DateField source="createdAt" />
                {tabValue === userStatusEnum.Deactivated && (
                    <DateField source="deactivatedAt" />
                )}
                <ConditionalButtons />
                <br />
            </Datagrid>
        </Fragment>
    );
};

export default TabbedDatagrid;
