import React, { Fragment, useState } from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    useListContext,
    EmailField,
    useRecordContext,
} from 'react-admin';

import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import userStatusEnum from '../../../enums/user-status.enum';
import RestoreAccessUserButton from '../components/restore-access-user-button';
import SuspendUserButton from '../components/suspend-user-button';
import DeleteUserButton from '../components/delete-user-button';
import ReinviteUserButton from '../components/reinvite-user-button';

const ConditionalButtons = () => {
    const record = useRecordContext();

    if (record.status === userStatusEnum.Deactivated) {
        return (
            <>
                <RestoreAccessUserButton
                    disabled={false}
                    name={'restore access'}
                />
            </>
        );
    }

    if (record.status === userStatusEnum.Suspended) {
        return (
            <>
                <RestoreAccessUserButton
                    disabled={false}
                    name={'restore access'}
                />
                <DeleteUserButton disabled={false} name={'delete'} />
            </>
        );
    }

    if (record.status === userStatusEnum.Active) {
        return (
            <>
                <SuspendUserButton disabled={false} name={'suspend'} />
                <DeleteUserButton disabled={false} name={'delete'} />
            </>
        );
    }

    if (record.status === userStatusEnum.Invited) {
        return (
            <>
                <ReinviteUserButton disabled={false} name={'reinvite'} />
                <DeleteUserButton disabled={false} name={'delete'} />
            </>
        );
    }
};

const UserListTabbedDatagrid = props => {
    const context = useListContext();

    const [tabValue, setTabValue] = useState(() =>
        context.filterValues && context.filterValues.status === 'deactivated'
            ? 'deactivated'
            : 'active'
    );
    const tabs = [
        { id: 'active', name: 'Active' },
        { id: 'deactivated', name: 'Deactivated' },
    ];

    const handleChange = (event, value) => {
        if (value === tabValue) {
            return;
        } else if (value === 'active') {
            setTabValue('active');
            context.setFilters({
                ...context.filterValues,
                status: null,
            });
        } else {
            setTabValue(userStatusEnum.Deactivated);
            context.setFilters({
                ...context.filterValues,
                status: userStatusEnum.Deactivated,
                role: null,
            });
        }
    };

    return (
        <Fragment>
            <Tabs
                fullWidth
                centered
                value={tabValue}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={choice.name}
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider />
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="companyName" />
                <TextField source="companyStatus" />
                <TextField source="firstName" />
                <TextField source="lastName" />
                <EmailField source="email" />
                <TextField source="role" />
                <DateField source="createdAt" />
                <TextField source="status" />
                <TextField source="onboardingStatus" />
                {tabValue === userStatusEnum.Deactivated && (
                    <DateField source="deactivatedAt" />
                )}
                <ConditionalButtons />
                <br />
            </Datagrid>
        </Fragment>
    );
};

export default UserListTabbedDatagrid;
