import { SelectInput } from 'react-admin';
import { useState, useEffect } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import baseUrl from '../enums/base-url.enum';

const CountrySelectInput = props => {
    const [countries, setCountries] = useState([]);
    useEffect(() => {
        async function fetchData() {
            const neededBaseUrl = baseUrl.baseUrl.split('admin')[0];
            fetch(neededBaseUrl + `dictionary/countries`, {
                method: 'GET',
            }).then(data => {
                data.json().then(function (data) {
                    setCountries(data.items);
                });
            });
        }

        fetchData();
    }, []);
    return (
        <SelectInput
            {...props}
            optionText="name"
            optionValue="alpha_2"
            source="country"
            choices={countries}
        />
    );
};

export default CountrySelectInput;
