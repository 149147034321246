import * as React from 'react';
import { useState } from 'react';
import {
    useNotify,
    useRedirect,
    Button,
    useRecordContext,
    useRefresh,
} from 'react-admin';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import baseUrl from '../../../enums/base-url.enum';
import httpHeader, { withAcsrf } from '../../../utils/http-header';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import companyStatusEnum from '../../../enums/company-status.enum';
import userStatusEnum from '../../../enums/user-status.enum';
import ErrorHandler from '../../../utils/error-handler';

const RestoreAccessUserButton = ({ disabled, name }) => {
    const record = useRecordContext();
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();
    const [loading, setLoading] = useState(false);
    let deactivate;
    if (disabled) {
        deactivate = true;
    } else {
        deactivate = loading;
    }

    const url =
        record.status === companyStatusEnum.Deactivated
            ? `/user/${record.id}/restore-deactivated`
            : `/user/${record.id}/restore-access`;

    const handleClick = async () => {
        setLoading(true);
        fetch(baseUrl.baseUrl + url, {
            method: 'POST',
            headers: await withAcsrf(httpHeader()),
        })
            .then(data => {
                data.json()
                    .then(function (data) {
                        if (data.status === 'error') {
                            ErrorHandler(data, redirect, notify);
                            notify(`${data.message}`, { type: 'warning' });
                            redirect('/user');
                        } else if (
                            record.status === userStatusEnum.Deactivated
                        ) {
                            // NOTE: this page need to move to general pages
                            // its part of reinvitation of user after restore access!!
                            redirect(
                                '/rapc-admin/invitation/confirmation',
                                null,
                                null,
                                {},
                                { data }
                            );
                            notify('User Access Restored!', {
                                type: 'success',
                            });
                        } else {
                            redirect('/user');
                            notify('User Access Restored!', {
                                type: 'success',
                            });
                            refresh();
                        }
                    })
                    .catch(function (e) {
                        notify(e);
                        redirect('/user');
                    });
            })
            .catch(e => {
                deactivate = false;
                notify('Error: user restore access failed', 'warning');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const submit = () => {
        confirmAlert({
            title: 'Confirm to restore access',
            message: 'Are you sure to restore access of current user?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleClick(),
                },
                {
                    label: 'No',
                    onClick: () => redirect('/user'),
                },
            ],
        });
    };
    return (
        <Button
            label={name}
            icon={SwitchAccessShortcutIcon}
            onClick={submit}
            disabled={deactivate}
        >
            <SwitchAccessShortcutIcon />
        </Button>
    );
};

export default RestoreAccessUserButton;
