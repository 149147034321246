import * as React from 'react';
import { cloneElement } from 'react';
import {
    useListContext,
    TopToolbar,
    useRedirect,
    Button,
    sanitizeListRestProps,
} from 'react-admin';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

/**
 * @NOTE
 * List Action component give us ability to customize
 * buttons on the toolbar on the top of list of records
 */

const UserListActions = props => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const { resource, displayedFilters, filterValues, showFilter } =
        useListContext();
    const redirect = useRedirect();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}

            {/* Add your custom actions */}
            <Button
                onClick={() => {
                    redirect('/user-custom/invitation-user');
                }}
                label="invite user"
            >
                <AddCircleOutlineIcon />
            </Button>
        </TopToolbar>
    );
};

export default UserListActions;
